import React from "react";
import { Button } from "src/components/button";
import { ReactComponent as CaretRight } from "./caret-right.svg";
import { ReactComponent as CheckmarkSvg } from "./checkmark.svg";

const Plan: React.FC<{
  name: string;
  price: string | null;
  ctaUrl: string;
  description: string;
  features: (string | React.ReactNode)[];
  addons?: (string | React.ReactNode)[];
}> = ({ name, price, ctaUrl, description, features, addons }) => {
  return (
    <div
      style={{ boxShadow: "0px 20px 24px rgba(0, 0, 0, 0.1)" }}
      className="rounded-[30px] bg-white overflow-hidden"
    >
      <div className="pt-[45px] px-[30px]">
        <h2 className="text-brand-green text-[30px] leading-[40px] mb-[40px]">
          {name}
        </h2>
        <div
          className="font-medium leading-[75px] -tracking-[0.0275em] mb-[25px]"
          style={{ fontSize: "min(6.9vw, 77px)" }}
        >
          {price ?? "Contact us"}
        </div>
        {price ? (
          <div className="text-[22px] leading-[24px] mb-[30px]">
            CAD
            <br />
            per driver / week
          </div>
        ) : (
          <div className="text-[22px] leading-[24px] mb-[30px]">
            to learn more about how Routes fits the needs of your enterprise.
          </div>
        )}
        {price ? (
          <div className="mb-[30px]">
            <Button block as="a" href={ctaUrl}>
              Sign Up Now
            </Button>
          </div>
        ) : (
          <div className="mb-[30px]">
            <Button block as="a" href={ctaUrl}>
              Learn More
            </Button>
          </div>
        )}
      </div>
      <div className="bg-cyan-6 px-[30px] pt-[30px] pb-[45px] sm:min-h-full">
        <h3 className="text-[22px] leading-[28px] mb-[30px]">{description}</h3>
        <h3 className="my-[20px] font-bold">Features</h3>
        <ul className="space-y-[18px]">
          {features.map((item: string | React.ReactNode, ix: number) => (
            <li
              key={ix}
              className="flex flex-row space-x-3 leading-[20px] -tracking-[0.006em] text-[17px]"
            >
              <CheckmarkSvg className="min-w-[20px]" />
              <span className="">{item}</span>
            </li>
          ))}
        </ul>
        {addons && (
          <>
            <h3 className="my-[20px] font-bold">Addons</h3>
            <ul className="space-y-[18px]">
              {addons.map((item: string | React.ReactNode, ix: number) => (
                <li
                  key={ix}
                  className="flex flex-row space-x-3 leading-[20px] -tracking-[0.006em] text-[17px]"
                >
                  <CheckmarkSvg className="min-w-[20px]" />
                  <span className="">{item}</span>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export const Plans: React.FC = () => {
  return (
    <section id="pricing">
      <div className="xs:px-[20px] sm:px-[5%] xs:py-[80px] sm:py-[110px] lg:px-[125px] bg-beige">
        <h1 className="xs:text-[30px] xs:leading-[35px] xs:pb-[70px] sm:text-[34px] sm:leading-[40px] sm:max-w-[546px]">
          Whether your business has 1 driver or 100, we can help save you time
          and money.
        </h1>
        <div className="xs:flex xs:flex-col xs:space-y-[60px] sm:grid sm:grid-cols-2 sm:gap-x-[5%] sm:gap-y-0 mb-[60px] sm:space-y-0">
          <Plan
            name="Business Tier"
            price="$9.99"
            ctaUrl={process.env.REACT_APP_ROUTES_APP_URL!}
            description="Everything your business needs to plan and dispatch:"
            features={[
              "Automated route optimization",
              "Digital signature capture",
              "Photo capture & proof of delivery",
              "Delivery notifications",
              "iOS & Android driver apps",
            ]}
            addons={[
              "Live vehicle tracking - $4.99",
              "Knockr™ delivery exception automation - $1.99",
              "More to come!",
            ]}
          />
          <Plan
            name="Enterprise Tier"
            price={null}
            ctaUrl={"mailto:" + process.env.REACT_APP_CONTACT_US_EMAIL}
            description="For teams who want their self-delivery capabilities to grow with their business:"
            features={[
              <span>
                Everything from{" "}
                <strong className="font-medium">Business Tier</strong> plus...
              </span>,
              "Custom enterprise integrations",
              "Driver analytics",
              "Live traffic data",
              "Custom branded tracking page",
              "On-Demand Optimization™",
            ]}
          />
        </div>
        <p className="text-center">
          Questions about pricing or a custom package?{" "}
          <a
            href={"mailto:" + process.env.REACT_APP_CONTACT_US_EMAIL}
            className="text-dark-green leading-[26px]"
          >
            Talk to sales
            <CaretRight className="inline ml-[14px] font-medium align-sub" />
          </a>
        </p>
      </div>
    </section>
  );
};
