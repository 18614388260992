import React from "react";
import { Link } from "react-router-dom";
import { Hr } from "src/components/hr";
import { SocialLinks } from "src/components/social-links";
import { StoreLinks } from "../store-links";
import { ReactComponent as MapleLeafSvg } from "./maple-leaf.svg";
import { ReactComponent as RoutesLogo } from "./routes-logo.svg";

const fullYear = new Date().getFullYear();

export const Footer: React.FC = () => {
  return (
    <section className="xs:px-[20px] sm:px-[5%] xs:pt-[60px] pb-[110px] text-white bg-navy">
      <div className="w-[138px] mb-[30px]">
        <RoutesLogo />
      </div>
      <div className="flex flex-col lg:flex-row lg:place-content-between space-y-[50px] mb-[50px] lg:space-y-0">
        <p className="text-[24px] leading-[34px] sm:max-w-[547px]">
          Self-delivery for smarter business.
        </p>
        <StoreLinks />
      </div>
      <div className="mb-[40px]">
        <Hr />
      </div>
      <div className="leading-[21px] text-[15px]">
        <div className="mb-[40px] lg:hidden">
          <SocialLinks />
        </div>
        <div className="lg:flex flex-row lg:place-content-between lg:mb-[40px] items-center">
          <div className="flex flex-row space-x-6 mb-[20px] lg:mb-0">
            <a href="https://virtual.com/" target="_blank" rel="noreferrer">
              A Virtual Company
            </a>
            <MapleLeafSvg />
            <span>Proudly Canadian</span>
          </div>
          <div className="mb-[40px] sm:flex sm:flex-row sm:space-x-6 lg:mb-0">
            <p>© {fullYear} Routes. All rights reserved.</p>
            <div className="flex flex-row space-x-2">
              <Link to="/privacy">Privacy Policy</Link>
            </div>
            <div className="flex flex-row space-x-2">
              <Link to="/user-data">User Data Policy</Link>
            </div>
          </div>
        </div>
        <div className="flex lg:place-content-between">
          <div className="opacity-[0.7] sm:max-w-[547px]">
            <p className="mb-1">
              This site provides information and access to shipping services
              offered by Routes.io. All users of our online services are subject
              to the <Link to="/privacy">Privacy Policy</Link>.
            </p>
            <p className="text-xs">
              Apple and the Apple logo are trademarks of Apple&nbsp;Inc.,
              registered in the U.S. and other countries.
              Mac&nbsp;App&nbsp;Store is a service mark of Apple&nbsp;Inc.
              Google&nbsp;Play and the Google&nbsp;Play logo are trademarks of
              Google&nbsp;Inc.
            </p>
          </div>
          <div className="hidden lg:block">
            <SocialLinks />
          </div>
        </div>
      </div>
    </section>
  );
};
