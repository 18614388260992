import { FC, PropsWithChildren } from "react";

export const Subsection: FC<PropsWithChildren<{ title?: string }>> = (
  props
) => {
  if (props.title) {
    return (
      <>
        <strong>{props.title}</strong>
        <div className="my-[30px]">{props.children}</div>
      </>
    );
  } else {
    return <div className="my-[30px]">{props.children}</div>;
  }
};
