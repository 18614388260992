import React from "react";
import { ReactComponent as CaretRight } from "./caret-right.svg";
import mediumVideo1Poster from "./routes-mainbenefit1-firstframe.jpg";
import mediumVideo1 from "./routes-mainbenefit1.mp4";
import largeVideo1Poster from "./routes-mainbenefit2-large-firstframe.jpg";
import largeVideo1 from "./routes-mainbenefit2-large.mp4";
import mediumVideo2Poster from "./routes-mainbenefit2-mediumsmall-firstframe.jpg";
import mediumVideo2 from "./routes-mainbenefit2-mediumsmall.mp4";
import selfdelivery from "./routes-selfdelivery.jpg";
import selfdelivery2x from "./routes-selfdelivery@2x.jpg";
import selfdelivery3x from "./routes-selfdelivery@3x.jpg";
import { ReactComponent as SmartRoutesIcon } from "./smart-routes-icon.svg";
import { ReactComponent as WaypointIcon } from "./waypoint-icon.svg";

const Callout: React.FC<{
  alignHeader?: "left" | "right";
  headerIcon: React.ReactNode;
  headerText: string | React.ReactNode;
  text: string | React.ReactNode;
  video: typeof mediumVideo1;
  videoPoster: typeof mediumVideo1Poster;
  arrow?: boolean;
}> = ({
  alignHeader = "left",
  headerIcon,
  headerText,
  text,
  video,
  videoPoster,
  arrow = false,
}) => {
  const arrowClassNames =
    "w-0 h-0 border-navy xs:border-l-[14px] xs:border-r-[14px] border-solid xs:border-l-transparent xs:border-r-transparent xs:border-t-[14px] sm:border-t-transparent sm:border-t-0 sm:border-r-0 sm:border-b-[20px] sm:border-l-[20px]";

  return (
    <div className="relative xs:pt-[75px] sm:pt-0">
      <div
        className={`absolute flex xs:flex-col xs:items-center sm:flex-row ${
          alignHeader === "left"
            ? "xs:left-0 sm:-left-[65px] xs:top-0 sm:top-[65px]"
            : "xs:right-0 sm:-right-[65px] xs:top-0 sm:top-[20%] sm:flex-row-reverse sm:items-end"
        }`}
      >
        <div
          className={`bg-navy p-[19px] rounded-[12px] ${
            alignHeader === "right" ? "sm:rounded-bl-none" : ""
          }`}
        >
          <div className="flex flex-row space-x-2 text-cyan-88 items-center font-[15px] font-medium mb-[16px]">
            {headerIcon}
            <span>{headerText}</span>
          </div>
          <h3 className="text-white font-medium text-[24px]">{text}</h3>
        </div>
        {arrow && <div className={arrowClassNames}></div>}
      </div>
      <div>
        <video
          className="rounded-[30px]"
          poster={videoPoster}
          muted
          autoPlay
          loop
          playsInline
        >
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export const MainBenefits: React.FC = () => {
  return (
    <section id="benefits">
      <img
        className="w-full"
        src={selfdelivery}
        srcSet={`${selfdelivery2x} 2x, ${selfdelivery3x} 3x`}
        alt=""
      />
      <div className="xs:py-[80px] sm:py-[120px] lg:py-[100px] xs:px-[20px] sm:px-0 sm:w-[516px] sm:mx-auto lg:px-[120px] lg:w-auto lg:mx-0 lg:flex flex-row lg:gap-[137px] xs:space-y-[75px] sm:space-y-[60px] lg:space-y-0 flex-row-reverse">
        <div className="flex flex-col justify-center">
          <h2 className="xs:text-[41px] sm:text-[52px] xs:leading-[47px] sm:leading-[58px] pb-[45px] font-medium xs:-tracking-[0.02em] sm:-tracking-[0.025em] lg:text-[52px] lg:leading-[58px] lg:-tracking-[0.025em]">
            Fast & profitable{" "}
            <span className="whitespace-nowrap">self-delivery</span>
          </h2>
          <p className="pb-[30px] sm:text-[22px] sm:leading-[32px]">
            Save hours a day with an automatic workflow that guides your
            deliveries from start to finish.
          </p>
          <a
            href="#foo"
            className="inline-flex flex-row space-x-1 items-center text-dark-green sm:text-[22px] sm:leading-[32px] hidden"
          >
            <span>Start now</span> <CaretRight />
          </a>
        </div>
        <Callout
          alignHeader="left"
          headerIcon={<SmartRoutesIcon />}
          headerText="Smart routes"
          text={
            <>
              All your drivers
              <br />
              are on time...
            </>
          }
          video={mediumVideo1}
          videoPoster={mediumVideo1Poster}
        />
      </div>
      <div className="bg-off-white relative">
        <div className="lg:bg-transparent xs:py-[80px] sm:py-[120px] lg:py-[100px] xs:px-[20px] sm:px-0 sm:w-[516px] sm:mx-auto lg:px-[120px] lg:w-auto lg:mx-0 xs:space-y-[75px] sm:space-y-[60px] lg:space-y-0 lg:absolute lg:left-0 lg:right-0 lg:top-0 z-10 lg:bottom-0 lg:flex lg:items-center">
          <div className="lg:max-w-[427px]">
            <h2 className="xs:text-[41px] sm:text-[52px] xs:leading-[47px] sm:leading-[58px] pb-[45px] font-medium xs:-tracking-[0.02em] sm:-tracking-[0.025em] lg:text-[52px] lg:leading-[58px] lg:-tracking-[0.025em]">
              Delight more customers
            </h2>
            <p className="pb-[30px] sm:text-[22px] sm:leading-[32px]">
              Stay competitive by providing a 5-star delivery experience with
              full order visibility and awesome customer tracking.
            </p>
            <a
              href="#foo"
              className="inline-flex flex-row space-x-1 items-center text-dark-green sm:text-[22px] sm:leading-[32px] hidden"
            >
              <span>Learn more</span> <CaretRight />
            </a>
          </div>
          <div>
            <div className="xs:mt-[75px] sm:mt-[60px] lg:hidden">
              <Callout
                arrow
                alignHeader="right"
                headerIcon={<WaypointIcon />}
                headerText="Tracking"
                text="Only 1 stop away!"
                video={mediumVideo2}
                videoPoster={mediumVideo2Poster}
              />
            </div>
          </div>
        </div>
        <div className="hidden z-10 lg:flex absolute flex-row flex-row-reverse items-end right-[7%] top-[20%]">
          <div
            className={`hidden lg:block bg-navy p-[19px] rounded-[12px] rounded-bl-none`}
          >
            <div className="flex flex-row space-x-2 text-cyan-88 items-center font-[15px] font-medium mb-[16px]">
              <WaypointIcon />
              <span>Tracking</span>
            </div>
            <h3 className="text-white font-medium text-[24px]">
              Only 1 stop away!
            </h3>
          </div>
          <div className="w-0 h-0 border-navy border-solid border-l-transparent border-r-transparent border-t-transparent border-t-0 border-r-0 border-b-[20px] border-l-[20px]"></div>
        </div>
        <video
          width="100%"
          autoPlay
          muted
          loop
          playsInline
          className="hidden lg:block"
          poster={largeVideo1Poster}
        >
          <source src={largeVideo1} type="video/mp4" />
        </video>
      </div>
    </section>
  );
};
