import { ScrollRestoration } from "react-router-dom";
import { Cta } from "src/components/cta";
import { Footer } from "src/components/footer";
import { Landing } from "src/components/landing";
import { MainBenefits } from "src/components/main-benefits";
import { Plans } from "src/components/plans";
import { PrimaryNavigation } from "src/components/primary-navigation";
import { ValueProp } from "src/components/value-prop";

function App() {
  return (
    <div className="-tracking-[0.01em] text-lg text-navy max-w-[1500px] bg-white mx-auto">
      <ScrollRestoration />
      <PrimaryNavigation />
      <div className="max-w-[1240px] mx-auto relative">
        <Landing />
        <MainBenefits />
        <ValueProp />
        <Plans />
        <Cta />
        <Footer />
      </div>
    </div>
  );
}

export default App;
