import { Dialog, Disclosure, Menu } from "@headlessui/react";
import { animated, useSpring } from "@react-spring/web";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "src/components/button";
import { Hr } from "src/components/hr";
import { SocialLinks } from "src/components/social-links";
import { useScrollPosition } from "src/hooks/useScrollPosition";
import { ReactComponent as RoutesIoLogo } from "src/routesio-logo.svg";
import { ReactComponent as CaretDown } from "./caret-down.svg";
import { ReactComponent as CaretRight } from "./caret-right.svg";
import { ReactComponent as CaretUp } from "./caret-up.svg";
import { ReactComponent as EmailIcon } from "./email-icon.svg";
import { ReactComponent as SideMenuCloseIcon } from "./menu-close-icon.svg";
import { ReactComponent as SideMenuIcon } from "./menu-icon.svg";

const CONTACT_MENU_ITEMS = [
  {
    label: "Email us",
    href: "mailto:" + process.env.REACT_APP_CONTACT_US_EMAIL,
    icon: <EmailIcon />,
  },
  // { label: "1-888-655-6258", href: "tel:18886556258", icon: <PhoneIcon /> },
  // { label: "Live chat", href: "#chat", icon: <ChatIcon /> },
];

const AnimatedDisclosurePanel: React.FC<{
  isOpen: boolean;
  children?: React.ReactNode;
}> = ({ isOpen, children }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [isOpenChanged, setIsOpenChanged] = useState(false);

  const height = ref?.current?.offsetHeight;

  const disclosureSprings = useSpring({
    from: {
      height: isOpen ? 0 : height,
    },
    to: {
      height: isOpen ? height : 0,
    },
    immediate: !isOpenChanged,
  });

  useEffect(() => {
    setIsOpenChanged(true);
  }, [isOpen]);

  return (
    <Disclosure.Panel
      as={animated.nav}
      className="flex flex-col overflow-y-hidden relative"
      static
    >
      <animated.div style={disclosureSprings}>
        <div ref={ref} className="absolute bottom-0">
          {children}
        </div>
      </animated.div>
    </Disclosure.Panel>
  );
};

const AnimatedCaret: React.FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const springs = useSpring({
    from: { transform: `rotate(${isOpen ? 0 : 180}deg)` },
    to: { transform: `rotate(${isOpen ? 180 : 0}deg)` },
  });

  return (
    <animated.div style={springs}>
      <CaretUp />
    </animated.div>
  );
};

const SideMenu: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onPageLinkClick: (event: React.MouseEvent<HTMLElement>) => void;
}> = ({ isOpen = false, onClose = () => {}, onPageLinkClick = () => {} }) => {
  const [isBackdropShown, setIsBackdropShown] = useState(false);
  const drawerSprings = useSpring({
    from: { x: "100%" },
    to: { x: isOpen ? "0%" : "100%" },
    onStart: () => {
      if (isOpen) setIsBackdropShown(true);
    },
    onRest: () => {
      if (!isOpen) setIsBackdropShown(false);
    },
  });

  const navLinkStyleClassNames = "text-[34px]";
  const contactLinkClassNames = "flex flex-row space-x-4 items-center h-[44px]";

  return (
    <Dialog
      open={isBackdropShown}
      onClose={onClose}
      className={`fixed inset-0 z-50 overflow-hidden ${
        isBackdropShown ? "block" : "hidden"
      }`}
      static
    >
      <Dialog.Panel
        className="fixed w-[375px] h-full max-w-full bg-navy-90 px-[40px] py-[70px] text-white right-0"
        as={animated.div}
        style={drawerSprings}
      >
        <button className="absolute top-[20px] right-[20px]" onClick={onClose}>
          <SideMenuCloseIcon />
        </button>

        <nav className="flex flex-col space-y-[14px]">
          <a
            className={navLinkStyleClassNames}
            href="/#features"
            onClick={onPageLinkClick}
          >
            Features
          </a>
          <a
            className={navLinkStyleClassNames}
            href="/#pricing"
            onClick={onPageLinkClick}
          >
            Pricing
          </a>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className={navLinkStyleClassNames}>
                  <div className="flex flex-row items-center place-content-between">
                    <span>Contact sales</span>
                    <AnimatedCaret isOpen={open} />
                  </div>
                </Disclosure.Button>
                <AnimatedDisclosurePanel isOpen={open}>
                  {CONTACT_MENU_ITEMS.map(
                    ({ label, href, icon }, ix: number) => (
                      <a href={href} className={contactLinkClassNames} key={ix}>
                        {icon}
                        <span className="text-lg">{label}</span>
                      </a>
                    )
                  )}
                </AnimatedDisclosurePanel>
              </>
            )}
          </Disclosure>
          <Hr />
          <a
            className={navLinkStyleClassNames + " text-brand-green-80"}
            href={process.env.REACT_APP_ROUTES_APP_URL!}
          >
            Login
          </a>
          <a
            className={navLinkStyleClassNames + " text-brand-green-80"}
            href={"mailto:" + process.env.REACT_APP_CONTACT_US_EMAIL}
          >
            Learn more
          </a>
          <Hr />
          <SocialLinks />
        </nav>
      </Dialog.Panel>
    </Dialog>
  );
};

const DropdownMenuItems: React.FC<{
  children?: React.ReactNode;
  open: boolean;
}> = ({ open, children }) => {
  const [isMenuShown, setIsMenuShown] = useState(false);

  const menuSprings = useSpring({
    from: { opacity: 0, y: -20 },
    to: { opacity: open ? 1 : 0, y: open ? 0 : -20 },
    onStart() {
      if (open) setIsMenuShown(true);
    },
    onRest() {
      if (!open) setIsMenuShown(false);
    },
  });

  return (
    <Menu.Items
      static
      as={animated.div}
      style={{ boxShadow: "0px 15px 18px rgba(0, 0, 0, 0.15)", ...menuSprings }}
      className={`absolute mt-2 origin-top-left rounded-[12px] bg-navy-92 text-white py-[12px] font-normal ${
        isMenuShown ? "block" : "hidden"
      }`}
    >
      {children}
    </Menu.Items>
  );
};

export const PrimaryNavigation: React.FC<{ shouldShowNav?: boolean }> = (
  props
) => {
  const scrollPosition = useScrollPosition();
  const navEl = useRef<null | HTMLElement>(null);

  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const hasHtmlOverflowHidden =
    document.documentElement.style.overflow === "hidden";
  const shouldShowNav =
    props.shouldShowNav ||
    scrollPosition !== 0 ||
    isSideMenuOpen ||
    hasHtmlOverflowHidden;

  const navSprings = useSpring({
    from: {
      opacity: shouldShowNav || scrollPosition === 0 ? 0 : 1,
      y: shouldShowNav || scrollPosition === 0 ? "-100%" : "0",
    },
    to: {
      opacity: shouldShowNav ? 1 : 0,
      y: shouldShowNav ? "0%" : "-100%",
    },
  });

  function handleOnPageNavigation(event: React.MouseEvent<HTMLElement>) {
    const href = (event.target as HTMLElement).getAttribute("href");
    const targetElement =
      href && window.document.getElementById(href.replace("#", ""));

    if (!targetElement || !navEl.current) return;

    event.preventDefault();

    setTimeout(() => {
      if (isSideMenuOpen) {
        setIsSideMenuOpen(false);

        return setTimeout(() => {
          if (!navEl.current) return;

          targetElement.scrollIntoView({
            block: "start",
            inline: "nearest",
            behavior: "smooth",
          });
        }, 1500);
      }

      setTimeout(() => {
        if (!navEl.current) return;

        var headerOffset = navEl.current.getBoundingClientRect().height;
        var elementPosition = targetElement.getBoundingClientRect().top;
        var offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }, 500);
    }, 200);

    window.location.hash = href;
  }

  return (
    <>
      <animated.nav
        ref={navEl}
        style={{
          boxShadow: "0 0 16px rgba(0,0,0,0.16)",
          maxWidth: "inherit",
          ...navSprings,
        }}
        className={`sm:h-[90px] xs:h-[70px] fixed top-0 w-full z-20 bg-white sm:px-[40px] flex place-content-between lg:grid grid-cols-12 grid-rows-1 items-center -translate-y-full`}
      >
        <div className="block xs:px-[19px] xs:py-[14px] col-span-3">
          <Link to="/">
            <RoutesIoLogo />
          </Link>
        </div>

        <nav className="hidden lg:flex flex-row space-x-10 items-center font-medium col-span-6 justify-center">
          <a href="/#features" onClick={handleOnPageNavigation}>
            Features
          </a>
          <a href="/#pricing" onClick={handleOnPageNavigation}>
            Pricing
          </a>
          <Menu as="div" className="relative">
            {({ open }) => (
              <>
                <Menu.Button>
                  <a
                    href="#contact"
                    className="flex flex-row space-x-2 items-center whitespace-nowrap"
                  >
                    <span>Contact Sales</span>
                    <CaretDown />
                  </a>
                </Menu.Button>
                <DropdownMenuItems open={open}>
                  {CONTACT_MENU_ITEMS.map(
                    ({ label, href, icon }, ix: number) => (
                      <Menu.Item key={ix}>
                        {({ active }) => (
                          <a
                            className={`block px-[20px] py-[11px] flex flex-row items-center space-x-4 ${
                              active && "bg-navy"
                            }`}
                            href={href}
                          >
                            {icon}
                            <span className="whitespace-nowrap grow">
                              {label}
                            </span>
                            <div className={active ? "visible" : "invisible"}>
                              <CaretRight />
                            </div>
                          </a>
                        )}
                      </Menu.Item>
                    )
                  )}
                </DropdownMenuItems>
              </>
            )}
          </Menu>
        </nav>

        <div className="flex flex-row space-x-3 col-span-3 justify-end">
          <div className="xs sm:flex flex-row space-x-3">
            <a
              href={process.env.REACT_APP_ROUTES_APP_URL!}
              className="font-medium pt-[12px] pb-[16px] px-[25px] xs:hidden sm:block"
            >
              Login
            </a>
            <Button
              href={"mailto:" + process.env.REACT_APP_CONTACT_US_EMAIL}
              variant="wire"
              block
            >
              Learn more
            </Button>
          </div>
          <button
            className="block px-[19px] py-[14px] lg:hidden"
            onClick={() => setIsSideMenuOpen(true)}
          >
            <SideMenuIcon />
          </button>
        </div>
      </animated.nav>
      <SideMenu
        isOpen={isSideMenuOpen}
        onClose={() => setIsSideMenuOpen(false)}
        onPageLinkClick={handleOnPageNavigation}
      />
    </>
  );
};
