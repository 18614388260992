import Lottie from "lottie-react";
import React from "react";
import fuelAnimation from "./icon1-fuel.json";
import workAnimation from "./icon2-workload.json";
import winAnimation from "./icon3-win.json";
import signatureAnimation from "./icon4-signature.json";
import planetAnimation from "./icon5-planet.json";
import extendAnimation from "./icon6-extend.json";

const Proposition: React.FC<{
  image: React.ReactNode;
  heading: React.ReactNode;
  description: React.ReactNode;
}> = ({ image, heading, description }) => {
  return (
    <div>
      <div className="pb-[30px] w-[130px] h-[80px] mb-[30px]">{image}</div>
      <h3 className="font-medium text-[24px] leading-[26px] mb-[13px]">
        {heading}
      </h3>
      <p className="text-[18px] leading-[26px] -tracking-[0.01em]">
        {description}
      </p>
    </div>
  );
};

export const ValueProp: React.FC = () => {
  return (
    <section
      id="features"
      className="xs:px-[20px] xs:py-[80px] sm:py-[110px] sm:px-[40px] lg:px-[125px]"
    >
      <h2 className="font-medium xs:text-[54px] xs:leading-[54px] xs:pb-[70px] sm:text-[100px] sm:leading-[100px] sm:pb-[110px]">
        Grow your
        <br />
        Business
      </h2>

      <div className="xs:space-y-[70px] sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-10 sm:gap-y-[90px] lg:grid-cols-3 lg:gap-x-[70px]">
        <Proposition
          image={<Lottie animationData={fuelAnimation} />}
          heading="Reduce fuel costs"
          description="Optimized delivery routes help maximize each driver’s shift."
        />

        <Proposition
          image={<Lottie animationData={workAnimation} />}
          heading="Cut your workload"
          description="Eliminate hours of boring manual work and disruptive customer queries to focus on running your business."
        />

        <Proposition
          image={<Lottie animationData={winAnimation} />}
          heading="Win customers"
          description="Get more orders on the road faster. Provide a better delivery experience than even the big guys can."
        />

        <Proposition
          image={<Lottie animationData={signatureAnimation} />}
          heading="Stay compliant"
          description="Pharmacy and alcohol deliveries can export digital records of GPS, age verification, and proof of delivery to remain compliant."
        />

        <Proposition
          image={<Lottie animationData={planetAnimation} />}
          heading="Help the planet"
          description="Empower your drivers with efficient routes to lower carbon emissions. "
        />

        <Proposition
          image={<Lottie animationData={extendAnimation} />}
          heading="Extend your reach"
          description="Stay competitive and thrive in your market by reaching and retaining more local customers.
"
        />
      </div>
    </section>
  );
};
