import React from "react";
import { ReactComponent as FacebookIcon } from "./facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "./instagram-icon.svg";
import { ReactComponent as LinkedInIcon } from "./linkedin-icon.svg";
import { ReactComponent as TwitterIcon } from "./twitter-icon.svg";

export const SocialLinks: React.FC = () => {
  return (
    <nav className="flex flex-row space-x-4">
      <a
        href="https://www.linkedin.com/company/routes-io"
        target="_blank"
        rel="noreferrer"
      >
        <LinkedInIcon />
      </a>
      <a href="#foo" className="hidden">
        <FacebookIcon />
      </a>
      <a href="#foo" className="hidden">
        <TwitterIcon />
      </a>
      <a href="#foo" className="hidden">
        <InstagramIcon />
      </a>
    </nav>
  );
};
