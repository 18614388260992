import { FC, useState } from "react";
import { ScrollRestoration } from "react-router-dom";
import { Button } from "./components/button";
import { Footer } from "./components/footer";
import { PrimaryNavigation } from "./components/primary-navigation";
import { Section } from "./components/section";
import { Subsection } from "./components/subsection";

export const UserDataPolicy: FC = () => {
  const SUPPORT_EMAIL = "support@routes.io";

  const [accountEmail, setAccountEmail] = useState("");

  function mailtoHref() {
    return encodeURI(
      `mailto:${SUPPORT_EMAIL}?subject=Account deletion request: ${accountEmail}&body=Please delete the account ${accountEmail} and its associated data.`
    );
  }

  return (
    <div className="-tracking-[0.01em] text-lg text-navy max-w-[1500px] bg-white mx-auto">
      <ScrollRestoration />
      <PrimaryNavigation shouldShowNav />
      <div className="max-w-[1240px] mx-auto relative">
        <section id="user-data">
          <div className="xs:px-[20px] sm:px-[5%] xs:py-[80px] sm:py-[110px] lg:px-[125px] bg-beige">
            <h1 className="xs:text-[30px] xs:leading-[35px] xs:pb-[70px] sm:text-[34px] sm:leading-[40px] sm:max-w-[546px]">
              User Data Policy
            </h1>
            <div
              style={{ boxShadow: "0px 20px 24px rgba(0, 0, 0, 0.1)" }}
              className="rounded-[30px] bg-white overflow-hidden"
            >
              <div className="pt-[45px] px-[30px]">
                <Section title="Account Deletion">
                  <Subsection>
                    If you wish to delete your Routes (App Store ID:{" "}
                    <pre className="inline">6449304997</pre>, Google Play ID:{" "}
                    <pre className="inline">io.routes.app</pre>) account and
                    associated data, please submit the following form. Your
                    name, email address, and user profile data will be
                    permanently removed.
                  </Subsection>
                  <Subsection>
                    <strong>
                      Please note that account deletion is permanent and cannot
                      be undone!
                    </strong>
                  </Subsection>
                  <Subsection>
                    <div className="flex gap-4">
                      <input
                        value={accountEmail}
                        onChange={(e) => setAccountEmail(e.target.value)}
                        type="text"
                        placeholder="Account email"
                        className="border rounded-full px-6"
                        size={30}
                      ></input>
                      <Button href={mailtoHref()} variant="wire" block>
                        Request account deletion
                      </Button>
                    </div>
                  </Subsection>
                </Section>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
};
