import React from "react";
import { Button } from "src/components/button";
import { ReactComponent as RoutesIoLogo } from "src/routesio-logo.svg";
import { StoreLinks } from "../store-links";
import largeVideoPoster from "./routes-landing-large-firstframe.jpg";
import largeVideo from "./routes-landing-large.mp4";
import mediumVideoPoster from "./routes-landing-mediumsmall-firstframe.jpg";
import mediumVideo from "./routes-landing-mediumsmall.mp4";

export const Landing: React.FC = () => {
  return (
    <section id="landing" className="relative">
      <div className="relative z-10">
        <div className="flex flex-col xs:py-[60px] sm:py-[80px] xs:px-[18px] sm:px-[13%] lg:px-[60px] items-center text-center lg:items-start lg:text-left">
          <div className="xs:pb-[50px] sm:pb-[65px] lg:pb-[85px]">
            <RoutesIoLogo />
          </div>
          <h1 className="xs:text-[47px] xs:leading-[46px] pb-[45px] font-medium -tracking-[0.02em] sm:text-[77px] sm:leading-[75px] sm:-tracking-[0.0275em]">
            Deliver more
            <br />
            for less
          </h1>
          <p className="pb-[45px] sm:text-[24px] sm:leading-[34px] lg:max-w-[400px]">
            Reduce costs, delight customers, and help your drivers make the most
            efficient deliveries.
          </p>
          <div className="flex xs:flex-col lg:flex-row gap-6 align justify-center items-center">
            <Button href="/#pricing" size="lg">
              Sign up now
            </Button>
            <StoreLinks />
          </div>
        </div>
        <video
          width="100%"
          autoPlay
          muted
          loop
          className="xs:block lg:hidden"
          playsInline
          poster={mediumVideoPoster}
        >
          <source src={mediumVideo} type="video/mp4" />
        </video>
      </div>
      <video
        width="100%"
        autoPlay
        muted
        loop
        playsInline
        className="hidden lg:block lg:absolute bottom-0 right-0 min-h-full object-cover object-right-bottom"
        poster={largeVideoPoster}
      >
        <source src={largeVideo} type="video/mp4" />
      </video>
    </section>
  );
};
