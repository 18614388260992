import React from "react";
import TextTransition from "react-text-transition";
import { Button } from "src/components/button";
import { ReactComponent as CaretRightSvg } from "./caret-right.svg";
import screenshotImgSrc from "./screenshot@2x.png";

const TEXTS = [
  <>
    Your <span className="text-brand-green-80">local</span>
    <br className="sm:hidden" /> deliveries
  </>,
  <>
    Your <span className="text-brand-green-80">pharmacy</span>
    <br className="sm:hidden" /> deliveries
  </>,
  <>
    Your <span className="text-brand-green-80">alcohol</span>
    <br className="sm:hidden" /> deliveries
  </>,
];

export const Cta: React.FC = () => {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <section className="bg-navy-88 text-white xs:px-[20px] xs:py-[80px] sm:px-[67px] sm:pt-[110px] lg:px-[125px]">
      <h1 className="xs:text-[41px] xs:leading-[47px] sm:text-[52px] sm:leading-[58px] xs:-tracking-[0.02em] sm:-tracking-[0.025em] text-center font-medium mb-[45px]">
        <TextTransition
          className="justify-center m-auto"
          style={{ width: "100%" }}
        >
          {TEXTS[index % TEXTS.length]}
        </TextTransition>
        made easy
      </h1>
      <nav className="flex flex-row space-x-3 justify-center">
        <Button href={"mailto:" + process.env.REACT_APP_CONTACT_US_EMAIL}>
          Contact us
        </Button>
        <a
          href="#contact"
          className="px-[25px] pt-[12px] pb-[16px] font-medium flex flex-row space-x-[14px] items-center hidden"
        >
          <span>Contact us</span>
          <CaretRightSvg />
        </a>
      </nav>
      <img
        className="w-full hidden"
        src={screenshotImgSrc}
        alt="Web and mobile app screenshot"
      />
    </section>
  );
};
