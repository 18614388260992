import { FC } from "react";
import { ReactComponent as AppStoreButtonSvg } from "./app-store-button.svg";
import { ReactComponent as PlayStoreButtonSvg } from "./play-store-button.svg";

export const StoreLinks: FC = () => {
  return (
    <nav className="flex flex-row space-x-[24px]">
      <a href={process.env.REACT_APP_APPLE_STORE_URL} className="block">
        <AppStoreButtonSvg className="max-w-full" />
      </a>
      <a href={process.env.REACT_APP_GOOGLE_STORE_URL} className="block">
        <PlayStoreButtonSvg className="max-w-full" />
      </a>
    </nav>
  );
};
