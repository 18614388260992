import { FC, PropsWithChildren } from "react";

export const Section: FC<PropsWithChildren<{ title: string }>> = (props) => {
  return (
    <section>
      <h2 className="text-brand-green text-[34px] leading-[40px] mb-[40px]">
        {props.title}
      </h2>
      <div className="mb-[30px]">{props.children}</div>
    </section>
  );
};
