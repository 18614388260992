import React from "react";

type ButtonProps<C extends React.ElementType> = {
  as?: C;
  children?: React.ReactNode;
  variant?: "default" | "wire";
  size?: "md" | "lg";
  block?: boolean;
} & React.ComponentPropsWithoutRef<C>;

export const Button = <C extends React.ElementType>({
  as,
  size = "md",
  variant = "default",
  block = false,
  children,
  ...restProps
}: ButtonProps<C>) => {
  const Component = as || "a";
  const variantClassNames = {
    default: "hover:bg-cyan-88 hover:shadow-lg bg-gold-88 text-navy",
    wire: "font-medium text-dark-green border-[1px] border-solid border-dark-green hover:bg-dark-green hover:text-white",
  }[variant];

  const classNames = {
    lg: "xs:px-[25px] xs:pt-[12px] xs:pb-[16px] sm:px-[27px] sm:pt-[14px] sm:pb-[18px] sm:text-[21px]",
    md: "pt-[12px] pb-[14px] px-[25px]",
  }[size];

  return (
    <Component
      {...restProps}
      className={[
        "transition text-center duration-300 rounded-full whitespace-nowrap",
        classNames,
        variantClassNames,
        block ? "block" : "",
      ]
        .filter(Boolean)
        .join(" ")}
    >
      {children}
    </Component>
  );
};
